import img from './image.png';
import background from './background.png';
import UnderConstruction from './components';

function App() {
  return <UnderConstruction
    background={{
      image: background,
      textColor: '#fff',
      overlay: {
        color: '#000',
        opacity: '.5'
      }
    }}
    logo={{
      src: img,
      alt: 'image',
      style: {
        width: '600px'
      }
    }}
    description={{
      text: 'Nuestro sitio web está en construcción, para saber más sobre nosotros contactanos por Facebook, Instragram o Correo Electrónico',
      style: {
        maxWidth: '440px',
      }
    }}
    // subscribe={{
    //   placeholder: 'Enter your email',
    //   buttonText: 'Subscribe',
    //   onSubmit: (value) => {
    //     console.log('user typed email :', value);
    //   }
    // }}
    links={[
      {
        url: 'https://instagram.com/ferreteria.bucarey',
        image: 'https://cdn.cdnlogo.com/logos/i/32/instagram-icon.svg',
      },
      {
        url: 'https://www.facebook.com/ferreteria.bucarey',
        image: 'https://cdn.cdnlogo.com/logos/f/91/facebook-icon.svg',
      },
      {
        url: 'mailto:hola@ferreteriabucarey.cl',
        image: 'https://cdn.cdnlogo.com/logos/m/46/mail-ios.svg',
      },
    ]}
  />
}

export default App;
